import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import DefaultLayout from '../layouts/default/defaultLayout'
import FrontpageBody from '../layouts/fontpage/FrontpageBody'
import Jumbotron from '../components/jumbotron/Jumbotron'
import { withIntl } from '../i18n'
import { graphql } from 'gatsby'

/** FONTS */
/* import '../styles/index.scss' */

class Frontpage extends React.Component {
  state = { title: this.props.intl.messages.frontpage.title, meta: this.props.intl.messages.frontpage.meta }

  static propTypes = {
    data: PropTypes.object,
    intl: PropTypes.object,
  }

  render() {
    return (
      <DefaultLayout>
        <Helmet
          meta={[
            { charset: 'utf-8' },
            { name: 'description', content: this.state.meta.description }
          ]}
          title={this.state.title}
        />
        <Jumbotron />
        <FrontpageBody
          images={this.props.data.FrontpageImgs.edges}
          partnersImages={this.props.data['PartnersSlider_' + this.props.intl.locale].edges}
        />
      </DefaultLayout>
    )
  }
}

export default withIntl(injectIntl(Frontpage))

export const query = graphql`
  query allFrontpageImgsQuery {
    FrontpageImgs: allFile(
      sort: { order: ASC, fields: [absolutePath] }
      filter: { relativeDirectory: { regex: "/frontpage/" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 480) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
    PartnersSlider_ru: allFile(
      sort: { order: ASC, fields: [name] }, 
      filter: { relativeDirectory: {regex: "images/partners/slider/ru/" } }
    ) {
      edges {
        node {
          name,
          publicURL
        }
      }
    }
    PartnersSlider_en: allFile(
      sort: { order: ASC, fields: [name] }, 
      filter: { relativeDirectory: {regex: "images/partners/slider/en/" } }
    ) {
      edges {
        node {
          name,
          publicURL
        }
      }
    }
  }
`
