import React from 'react'
import classnames from 'classnames'

import style from './FrontpageBody.module.scss'
import PropTypes from 'prop-types'
import Products from './components/Products'
import Demos from './components/Demos'
import Libraries from './components/Libraries'
/* import Testimonials from './components/Testimonials' */
import About from './components/About'
import Contacts from './components/Contacts'
import Partners from './components/Partners'

class FrontpageBody extends React.Component {
  static propTypes = {
    images: PropTypes.array,
    partnersImages: PropTypes.array
  }

  render() {
    return (
      <div className={classnames(style.fontpageBody, style.container)}>
        <Products images={this.props.images} />
        <Libraries />
        {/*<Demos />*/}
        {/* <Testimonials /> */}
        <Partners images={this.props.partnersImages} />
        <About />
        <Contacts />
      </div>
    )
  }
}

export default FrontpageBody
