import React, { PureComponent } from 'react'
import style from '../FrontpageBody.module.scss'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
/* import NextPrevGroup from '../../../components/buttons/nextPrevious/NextPrevGroup' */
import CardDemo from '../../../components/cards/CardDemo'
import Link from '../../../i18n/Link'
class Demos extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.intl.messages.frontpage.demos,
    }
  }

  static propTypes = {
    intl: PropTypes.object,
  }

  render() {
    const { data } = this.state
    return (
      <div id="demo" className={classnames(style.demoContainer, style.container)}>
        <div className={style.innerContainer}>
          <div className={style.innerTitle}>
            <h2 dangerouslySetInnerHTML={{ __html: data.heading }} />
            {/*  <NextPrevGroup /> */}
          </div>
          <div className={style.subTitle} dangerouslySetInnerHTML={{ __html: data.subheading }} />
          <div className={style.cardGroup}>
            {data.cards.map((x, i) => (
              <Link to={`/demos${x.href}`} key={`card-${i}`}>
                <CardDemo cardInfo={x} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(Demos)
