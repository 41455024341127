import React, { PureComponent } from 'react'
import style from '../FrontpageBody.module.scss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Card from '../../../components/cards/Card'

class Products extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.intl.messages.frontpage.products,
    }
  }

  static propTypes = {
    intl: PropTypes.object,
    images: PropTypes.array,
  }

  render() {
    const { data } = this.state
    return (
      <div id="products" className={classnames(style.productsContainer, style.container)}>
        <div className={style.innerContainer}>
          <div className={style.innerTitle}>
            <h2 dangerouslySetInnerHTML={{ __html: data.heading }} />
          </div>
          <div className={style.cardGroup}>
            {data.cards.map((x, i) => (
              <Card key={`card-${i}`} image={this.props.images[i]} cardInfo={x} />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(Products)
