import React, { PureComponent } from 'react'
import style from '../FrontpageBody.module.scss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Slider from 'react-slick'
import '../../../styles/plugins/slick.min.scss'
import '../../../styles/plugins/slick.scss'

class Partners extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.intl.messages.frontpage.partners,
      partners: this.props.intl.messages.partnersPage.partnersBlock
    }
  }

  static propTypes = {
    intl: PropTypes.object,
    images: PropTypes.array,
  }

  render() {
    const { data, partners } = this.state
    const { images } = this.props
    let logos = {};
    images.map(x => logos[x.node.name] = x.node.publicURL)
    const sliderSettings = {
      infinite: true,
      swipeToSlide: true,
      slidesToScroll: 1,
      slidesToShow: 5,
      responsive: [
        {
          breakpoint: 1149,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 949,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 499,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    }
    return (
      <div id="partners" className={classnames(style.partnersContainer, style.container)}>
        <h3 dangerouslySetInnerHTML={{ __html: data.heading }} />
        <div className={style.partnersSlider}>
          <Slider {...sliderSettings}>
            {partners.map((el, index) => {
              return (
                <a
                  key={index}
                  href={el.imgLink || 'javascript:void(0)'}
                  className={style.partnersItem}
                  style={logos[el.imgName] ? {} : { display: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logos[el.imgName] || ''} alt={el.name}/>
                </a>
              )
            })}
          </Slider>
        </div>
      </div>
    )
  }
}

export default injectIntl(Partners)
