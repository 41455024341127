import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import styles from './Jumbotron.module.scss'
import I18nLink from '../../i18n/Link'

class Jumbotron extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cards: props.intl.messages.frontpage.banner,
    }
  }

  static propTypes = {
    intl: PropTypes.object,
  }

  render() {
    const { cards } = this.state
    return (
      <div className={styles.jumbotron}>
        <h1 className={styles.header} dangerouslySetInnerHTML={{ __html: cards.h1 }} />
        <div className={styles.content}>
          {cards.config.map((x, i) => (
            <div key={`card-${i}`} className={styles.card}>
              <div className={styles.cardBody} dangerouslySetInnerHTML={{ __html: x.text }} />
              <I18nLink to={x.href}>
                <button className={x.btn === 'Подробнее' || x.btn === 'Read more' ? styles.cardButton : styles.cardButtonBlue} dangerouslySetInnerHTML={{ __html: x.btn }} />
              </I18nLink>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default injectIntl(Jumbotron)
