import React, { PureComponent } from 'react'
import style from '../FrontpageBody.module.scss'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Btn from '../../../components/buttons/btn/Btn'
import I18nLink from '../../../i18n/Link'

class Libraries extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.intl.messages.frontpage.libs,
    }
  }

  static propTypes = {
    intl: PropTypes.object,
  }

  render() {
    const { data } = this.state
    return (
      <div id="puzzlelib" className={classnames(style.libContainer, style.container)}>
        <div className={style.bannerContainer}>
          <div className={style.bannerTitle}>
            <h3 dangerouslySetInnerHTML={{ __html: data.heading }} />
          </div>
          <div className={style.bannerText} dangerouslySetInnerHTML={{ __html: data.text }} />
          <div className={style.btnContainer}>
            <I18nLink to="/puzzlelib">
              <Btn text={data.btnText} />
            </I18nLink>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(Libraries)
