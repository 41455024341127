import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import style from './Card.module.scss'

class CardDemo extends React.Component {
  static propTypes = {
    cardInfo: PropTypes.object,
  }

  render() {
    return (
      <div className={classnames(style.cardContainer, style.cardContainerDemo)}>
        <div className={style.cardBody}>
          <div className={style.cardIconContainer}>
            <FontAwesomeIcon size="4x" icon={['fas', 'expand']} color="#3e7af8" />
          </div>
          <div className={style.cardTitle}>
            <h4 dangerouslySetInnerHTML={{ __html: this.props.cardInfo.label }} />
          </div>
          <div className={style.cardText} dangerouslySetInnerHTML={{ __html: this.props.cardInfo.text }} />
        </div>
      </div>
    )
  }
}

export default CardDemo
