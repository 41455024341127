import React, { PureComponent } from 'react'
import style from '../FrontpageBody.module.scss'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Form from '../../../components/form/Form'

import YandexMaps from '../../../components/maps/YMap'

class Contacts extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.intl.messages.frontpage.contacts,
    }
    this.map = React.createRef()
  }

  static propTypes = {
    intl: PropTypes.object,
  }

  render() {
    const { data } = this.state
    return (
      <div id="contact" className={classnames(style.contactsContainer, style.container)}>
        <div className={style.mapBox}>
          <h3 dangerouslySetInnerHTML={{ __html: data.heading }} />
          <a className={style.tel} href={`tel:${data.telLink}`}>
            {data.tel}
          </a>
          <a className={style.email} href={`mailto:${data.email}`}>
            {data.email}
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://yandex.ru/maps/213/moscow/?ll=37.443349%2C55.709553&mode=usermaps&source=constructorStatic&um=constructor%3AAI__fX87ZIMEBxORsU402SMOYjbdkYYI&z=16"
            className={style.address}
          >
            {data.address}
          </a>
          <YandexMaps />
        </div>
        <div className={style.formContainer}>
          <h3 dangerouslySetInnerHTML={{ __html: data.formHeading }} />
          <Form amoForm />
        </div>
      </div>
    )
  }
}

export default injectIntl(Contacts)
