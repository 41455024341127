import React from 'react'
import style from './Btn.module.scss'
import PropTypes from 'prop-types'

class Btn extends React.Component {
  static propTypes = {
    text: PropTypes.string,
  }

  static defaultProps = {
    text: 'Подробнее',
  }

  render() {
    return <button className={style.btn} dangerouslySetInnerHTML={{ __html: this.props.text }} />
  }
}

export default Btn
