import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import style from './Card.module.scss'
import I18nLink from '../../i18n/Link'
import Img from 'gatsby-image'

class Card extends React.Component {
  static propTypes = {
    cardInfo: PropTypes.object,
    image: PropTypes.object,
  }

  render() {
    return (
      <div className={style.cardContainer}>
        <div className={style.cardImage}>
          <Img sizes={this.props.image.node.childImageSharp.sizes} />
        </div>
        <div className={style.cardBody}>
          <div className={style.cardTitle}>
            <h4>{this.props.cardInfo.label || 'Название'}</h4>
          </div>
          <div className={style.cardText}>{this.props.cardInfo.text || 'Текст'}</div>
        </div>
        <I18nLink to={this.props.cardInfo.href}>
          <div className={style.cardFooter}>
            <p className={style.cardBtn} dangerouslySetInnerHTML={{ __html: this.props.cardInfo.btn }} />
            <FontAwesomeIcon size="3x" icon={['fas', 'arrow-right']} transform="shrink-8" color="#3e7af8" />
          </div>
        </I18nLink>
      </div>
    )
  }
}

export default Card
